import * as React from 'react';
import styled from 'styled-components';
import MessageInputOptions from 'src/pages/MessengerPage/messenger/messages-layout/input-section/MessagesInputOptions';
import MessagesInputContainer from 'src/pages/MessengerPage/messenger/messages-layout/input-section/MessagesInputContainer';
import {Mutation, MutationFunction} from 'react-apollo';
import {AuthContext} from 'src/auth/AuthProvider';
import SendMessageMutation from 'src/gql/mutation/SendMessageMutation';
import {
  AuthPayload,
  Message,
  User,
  ConsultMessageData,
  SendMessageMutationVariables,
  PriorityType,
  MessageObjectType,
} from 'src/types';
import {tryFileUpload, trySendMessage} from 'src/utils/messengerHelper/sendMessage';
import {connect} from 'react-redux';
import {actions as MessageActions} from 'src/redux/actions/messages';
import {actions as FiledropActions} from 'src/redux/actions/filedrop';
import store from 'src/redux';
import {toast} from 'react-toastify';
import MessageTemplateListModal from 'src/pages/MessengerPage/messenger/messages-layout/MessageTemplateListModal';
import scrollMessageBodyToBottom from 'src/utils/messengerHelper/scrollMessageBodyToBottom';
import MessageObject from 'src/utils/messengerHelper/MessageObject';
import {ON_SHIFT, BusyUnavailableDialogText} from 'src/constants/workStatus';
import AlertModal from '../message-template/AlertModal';
import handleGotoAlternativeContact from 'src/utils/messengerHelper/SwitchChatToAlternateContact';
import {ApolloQueryResult} from 'apollo-client';

const Container = styled.div`
  max-height: 180px;
  padding: 0.5em;
`;

const Interactables = styled.div`
  display: flex;
  padding-left: 0.5em;
`;

const Status = styled.div`
  line-height: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  color: grey;
`;

interface Props {
  sendMessage: MutationFunction<{}, SendMessageMutationVariables>;
  chatId: string;
  authInfo: AuthPayload;
  disabled: boolean;
  addOptimisticMessage: (message: Message, chatId: string) => void;
  removeOptimisticMessage: (messageId: string, chatId: string) => void;
  failOptimisticMessageMark: (messageId: string, chatId: string) => void;
  appendDroppedFiles: (files: File[]) => void;
  clearDroppedFiles: () => void;
  isSingleChat: boolean;
  targetUser: User;
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;
  setIsSearch: (isSearch) => void;
  clearSearchOnNewMessage?: () => void;
  jumpToLatestMessageOnSearch?: boolean;
}

interface State {
  isMessageSending: boolean;
  isFileUploading: boolean;
  messagePriority: PriorityType;
  showModal: boolean;
  inputRef: HTMLTextAreaElement | null;
  userBusyOrUnavailableAlert: boolean;
}

class MessagesInputArea extends React.PureComponent<Props, State> {
  public state = {
    isMessageSending: false,
    isFileUploading: false,
    messagePriority: PriorityType.regular,
    showModal: false,
    inputRef: null,
    userBusyOrUnavailableAlert: false,
  };

  public setInputRef = (element: HTMLTextAreaElement) => {
    this.setState({
      inputRef: element,
    });
  };

  public componentDidUpdate(nextProps: Props) {
    if (nextProps.chatId !== this.props.chatId && this.state.inputRef) {
      this.state.inputRef.focus();
    }
  }

  public render() {
    const {isMessageSending, isFileUploading, messagePriority, showModal, inputRef, userBusyOrUnavailableAlert} =
      this.state;
    const {disabled, chatId, isSingleChat} = this.props,
      targetUser = this.props?.targetUser || null,
      workStatus = targetUser?.workStatus,
      firstname = targetUser?.firstname,
      lastname = targetUser?.lastname,
      workStatusProxy = targetUser?.workStatusProxy;

    return (
      <Container>
        <Interactables>
          <MessageInputOptions
            messagePriority={messagePriority}
            onExclamationButtonClick={this.onExclamationButtonClick}
            showConsultMessageModal={this.showMessageTemplateModal}
            handleFileUpload={this.handleFileUpload}
          />
          <MessagesInputContainer
            chatId={chatId}
            messagePriority={messagePriority}
            inputRef={inputRef}
            setInputRef={this.setInputRef}
            trySendMessage={this.prepareSendMessage}
            isFileUploading={isFileUploading}
            isDisabled={disabled}
            handleFileUpload={this.handleFileUpload}
          />
        </Interactables>
        <Status>
          {isMessageSending || isFileUploading
            ? isFileUploading
              ? 'Uploading Files...'
              : 'Sending Messages...'
            : 'Hit enter to send'}
        </Status>
        <MessageTemplateListModal
          chatId={chatId}
          isSingleChat={isSingleChat}
          showMessageTemplateListModal={showModal}
          openMessageTemplateListModal={this.showMessageTemplateModal}
          closeMessageTemplateListModal={this.closeMessageTemplateListModal}
        />
        {userBusyOrUnavailableAlert && (
          <AlertModal
            title={BusyUnavailableDialogText[workStatus].title(targetUser)}
            subtitle={BusyUnavailableDialogText[workStatus].subtitle(targetUser)}
            closeAlertModal={() => this.setState({userBusyOrUnavailableAlert: false})}
            isAlertModalVisible={userBusyOrUnavailableAlert}
            alertModalButtons={[
              {
                buttonLabel: `Continue with ${firstname || null} ${lastname || ''}`,
                type: 'leftOutlined',
                onClickHandler: () => {
                  this.setState({
                    userBusyOrUnavailableAlert: false,
                    showModal: true,
                  });
                },
              },
              {
                buttonLabel: 'Don’t send',
                type: 'secondary',
                onClickHandler: () => this.setState({userBusyOrUnavailableAlert: false}),
              },
              {
                buttonLabel: `Contact ${workStatusProxy?.firstname || null} ${workStatusProxy?.lastname || ''}`,
                type: Boolean(workStatusProxy) ? 'primary' : 'none',
                onClickHandler: () => handleGotoAlternativeContact(workStatusProxy),
              },
            ]}
          />
        )}
      </Container>
    );
  }

  private closeMessageTemplateListModal = () => {
    this.setState({
      showModal: false,
    });
  };

  private showMessageTemplateModal = () => {
    const targetUser = this.props.targetUser || null;
    if (targetUser && targetUser?.workStatus !== ON_SHIFT) {
      this.setState({userBusyOrUnavailableAlert: true});
    } else {
      this.setState({
        showModal: true,
      });
    }
  };

  private handleFileUpload = async (files: File[]) => {
    const currentFiles = store.getState().filedrop.files;
    if (currentFiles.length + files.length > 10) {
      toast.error('You can not select more than 10 files.');
      return;
    }

    const {appendDroppedFiles} = this.props;
    const uploadedFiles: File[] = [...files];

    appendDroppedFiles(uploadedFiles);
  };

  private onExclamationButtonClick = (priority) => {
    this.setState({
      messagePriority: priority,
    });
  };

  private trySendFileMessage = (files: File[], messagePriority: PriorityType) => {
    const {clearDroppedFiles} = this.props;
    clearDroppedFiles();
    this.setState(
      {
        isFileUploading: true,
      },
      async function () {
        try {
          const {
            chatId,
            sendMessage,
            authInfo: {
              user: {id},
            },
          } = this.props;

          let filesMessageObject = new MessageObject(null, messagePriority, chatId, id, null, null, files, null, null);

          await tryFileUpload({
            filesMessageObject,
            sendMessageMutation: sendMessage,
          });
        } catch (e) {
          console.error(e);
          toast.error('Upload file failed, please check your internet connection and try again');
        } finally {
          this.setState({
            isFileUploading: false,
          });
        }
      },
    );
  };

  private prepareSendMessage = async (message: string, consultData?: ConsultMessageData) => {
    this.setState({
      messagePriority: PriorityType.regular,
    });
    const files = store.getState().filedrop.files;
    const hasFiles = files && files.length > 0;
    if (message.trim() === '' && !consultData && !hasFiles) return;

    const {chatId, sendMessage, authInfo, addOptimisticMessage, failOptimisticMessageMark, removeOptimisticMessage} =
      this.props;

    try {
      const {messagePriority} = this.state;
      this.setState({
        isMessageSending: true,
      });

      if (hasFiles) this.trySendFileMessage(files, messagePriority);

      let newMessage: MessageObjectType = new MessageObject(
        message.trim(),
        messagePriority,
        chatId,
        authInfo?.user?.id,
        null,
        consultData ? consultData : null,
        null,
        null,
        null,
      );

      await trySendMessage(newMessage, {
        sendMessage,
        addOptimisticMessage,
        failOptimisticMessageMark,
        removeOptimisticMessage,
      });
      if (this.props.jumpToLatestMessageOnSearch) {
        // tigger search close function
        this.props.clearSearchOnNewMessage();
      } else {
        scrollMessageBodyToBottom();
      }
    } catch (e) {
      console.error(e);
      const messageBody = document.getElementById('scrollContainer');
      if (messageBody) messageBody.scrollTop = messageBody.scrollHeight;
    }
    this.setState({
      isMessageSending: false,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    targetUser: state.messages.targetUser,
  };
};

export default connect<{}, {}, any>(mapStateToProps, {
  addOptimisticMessage: MessageActions.addOptimisticMessage,
  removeOptimisticMessage: MessageActions.removeOptimisticMessage,
  failOptimisticMessageMark: MessageActions.failOptimisticMessageMark,
  appendDroppedFiles: FiledropActions.appendDroppedFiles,
  clearDroppedFiles: FiledropActions.clearDroppedFiles,
})((props) => (
  <AuthContext.Consumer>
    {({authInfo}) => (
      <Mutation mutation={SendMessageMutation}>
        {(sendMessage) => <MessagesInputArea {...props} sendMessage={sendMessage} authInfo={authInfo} />}
      </Mutation>
    )}
  </AuthContext.Consumer>
));
