import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';

import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import theme from 'src/styles/theme';
import styled from 'styled-components';
import {InputAdornment} from '@material-ui/core';
import SearchIcon from 'src/svgs/SearchIcon';

const SearchBarContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;
interface CustomSearchFieldProps {
  error?: boolean;
  searchError?: string;
  clearSearchText: () => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  localSearchText: string;
  placeHolder: string;
  inputWidth?: string;
  inputHeight?: string;
  showSearchIcon?: boolean;
  type?: string;
}

const CustomSearchField: React.FC<CustomSearchFieldProps> = ({
  error,
  searchError,
  clearSearchText,
  handleKeyDown,
  handleChange,
  localSearchText,
  placeHolder,
  inputWidth,
  inputHeight,
  showSearchIcon = true,
  type,
}) => {
  return (
    <SearchBarContainer>
      <StyledTextField
        error={error}
        type={type}
        helperText={error && searchError}
        variant="outlined"
        id="outlined-adornment-search"
        style={{background: 'white'}}
        inputProps={{
          style: {
            padding: '0px 2px 0px 8px',
            width: inputWidth,
            height: inputHeight ? inputHeight : '36px',
            borderRadius: '2px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        InputProps={{
          startAdornment: showSearchIcon && (
            <InputAdornment position="start">
              <SearchIcon width={20} height={20} stroke={theme.greyishBrown} />
            </InputAdornment>
          ),
          endAdornment: localSearchText ? (
            <InputAdornment
              position="end"
              onClick={clearSearchText}
              style={{
                cursor: 'pointer',
                visibility: localSearchText === '' ? 'hidden' : 'visible',
              }}
            >
              <ClearIcon width={20} height={20} stroke={theme.greyishBrown} />
            </InputAdornment>
          ) : null,
          onKeyDown: handleKeyDown,
        }}
        placeholder={placeHolder}
        value={localSearchText}
        onChange={handleChange}
      />
    </SearchBarContainer>
  );
};

export default CustomSearchField;
