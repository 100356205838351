import ApiHelper from '../../../../api';
import {AuthPayloadDTO, AuthSSOVendors, RequestVerificationPayload} from '../../../../types';
import {
  AccountStatus,
  OrganizationAccountsCacheData,
  OrgLoginMethods,
  OrgViews,
  WebViewCallBacks,
} from '../../../../types/sta';
import {checkIfUserHasLoginScope} from '../../../../utils/userHelper/userUtils';
import {
  CURRENT_SELECTED_ORGANIZATION,
  EMAIL,
  ORG_URL,
  ORGANIZATION_ACCOUNTS_DATA,
  SSO_VENDOR,
} from '../../../../constants/storageKeys';
import {
  TOO_MANY_CHALLENGES,
  TOO_MANY_CHALLENGES_MSG,
  VALIDATION_CODE_FAILED_SEND_MSG,
} from '../../../../constants/networkError';
import {localStorageService} from '../../../../services/localStorageService';
import {callNative} from '../../../../nativeBridge';
import {
  ACCOUNT_SELECTION_ACCOUNT_HAS_NO_ORG_ERROR,
  ACCOUNT_SELECTION_INACTIVE_ACCOUNT_ERROR,
  ACCOUNT_SELECTION_NO_MATCHED_ACCOUNT_ERROR,
  ACCOUNT_SELECTION_NO_SUPPORTED_LOGIN_METHODS_ERROR,
  ACCOUNT_SELECTION_USER_NOT_FOUND_ERROR,
} from '../../../../constants/strings';
import {DomainOrganization} from '../../../../gql/v2/query/FetchOrganizationsByDomains';
import {SSOProfile} from '../../../../gql/v2/query/ListSSOProfilesQuery';

export const AccountSelectionViewModel = () => {
  const getSavedAccountCacheData = () => {
    const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
    if (!currentCacheData?.savedOrganizations) {
      return null;
    }
    return currentCacheData;
  };

  const suggestedOrganizationClick = async (org: DomainOrganization) => {
    const currentCacheData = getSavedAccountCacheData();

    if (!currentCacheData?.savedOrganizations) return;

    const foundAccount = currentCacheData.savedOrganizations.find((acc) =>
      org.domains.includes(acc.user.email?.split('@')[1] || ''),
    );

    if (foundAccount) {
      await handleSuggestedOrganizationFlow(foundAccount.user.email || '', org);
    }
  };

  const handleSuggestedOrganizationFlow = async (email: string, org: DomainOrganization) => {
    const emailDomain = email?.split('@')[1];
    const isSSOLogin = org.domains.includes(emailDomain) && org.loginMethods.includes(OrgLoginMethods.SSO);

    if (isSSOLogin) {
      const ssoProfile = await findSSOProfileForOrg(org.id);

      if (ssoProfile) {
        return await handleSSOLogin(ssoProfile);
      }
    } else {
      return await handleEmailLogin(email, org);
    }
  };

  const findSSOProfileForOrg = async (orgId: number) => {
    const {data} = await ApiHelper.PublicEndpoints.listSSOProfiles();
    return data?.listSSOProfiles.find((profile) => profile.organization.id === orgId);
  };

  const handleSSOLogin = async (ssoProfile: SSOProfile) => {
    if (ssoProfile.auth0Id && ssoProfile.ssoVendor) {
      localStorage.setItem(SSO_VENDOR, ssoProfile.ssoVendor);

      if (ssoProfile.ssoVendor === AuthSSOVendors.WORKOS) {
        await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(ssoProfile.auth0Id, ssoProfile.ssoVendor);
        callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.SSO});
        return;
      } else {
        return {auth0Id: ssoProfile.auth0Id, address: ''};
      }
    }
  };

  const handleEmailLogin = async (email: string, org: DomainOrganization) => {
    const challengeId = await requestAddressOTP({address: email, type: 'email'});

    if (typeof challengeId === 'string') {
      const encodedEmail = encodeURIComponent(email);
      const orgUrl = `/signup?email=${encodedEmail}&challengeId=${challengeId}&orgUrl=${org.url}&step=2&activateShellAccountFlow=true&orgName=${org.name}`;

      window.routerHistory.push(orgUrl);
      callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
    } else {
      return challengeId;
    }
  };

  const checkForSuggestedOrganizationsInList = (
    currentCacheData: OrganizationAccountsCacheData,
    organizationsForDomains: DomainOrganization[],
  ) => {
    const savedOrganizationIds = new Set(currentCacheData.savedOrganizations.map((org) => org.organization.id));

    return organizationsForDomains.filter((org) => !savedOrganizationIds.has(org.id));
  };

  const getOrganizationByDomains = async (domains: string[]) => {
    const res = await ApiHelper.PublicEndpoints.fetchOrganizationsByDomains(domains);

    if ('errors' in res || 'error' in res) {
      return {error: 'Error occurred when fetching organizations by domains'};
    }

    if ('data' in res) {
      return res.data;
    }

    return [];
  };

  const retrieveSuggestedOrganizations = async () => {
    const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
    if (!currentCacheData?.savedOrganizations) {
      return [];
    }

    const extractedDomains = currentCacheData.savedOrganizations.map((org) => org.user.email?.split('@')[1] || '');

    if (extractedDomains && extractedDomains.length > 0) {
      const res = await getOrganizationByDomains(extractedDomains);

      if (res && 'errors' in res) {
        return [];
      }

      if ('organizationsForDomains' in res) {
        const suggestedOrganizations = checkForSuggestedOrganizationsInList(
          currentCacheData,
          res.organizationsForDomains,
        );
        if (suggestedOrganizations) {
          return suggestedOrganizations;
        }
      }
    }
  };
  const getBasicUser = async (email: string, orgUrl: string) => {
    const res = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);

    if (res?.data?.user.__typename === 'UserNotFound') {
      return 'UserNotFound';
    }

    if (res.data) {
      return res.data.user;
    }
  };

  const handleLoginToClickedAccount = async (accountInformation: AuthPayloadDTO) => {
    const {organization, user} = accountInformation;
    const orgUrl = organization.url;
    const currentSavedAccountData =
      localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    const userAddress = user.email || user.username || '';

    window.localStorage.setItem(ORG_URL, orgUrl);
    window.localStorage.setItem(EMAIL, userAddress || '');
    localStorage.setItem(CURRENT_SELECTED_ORGANIZATION, JSON.stringify(organization));

    window.localStorage.removeItem('flowStep');

    if (
      currentSavedAccountData?.savedOrganizations.some(
        (org) => org.user.id === accountInformation.user.id && org.accessToken,
      )
    ) {
      return {
        screen: 'Login',
      };
    }

    const basicUser = await getBasicUser(userAddress, orgUrl);

    if (!basicUser || basicUser === 'UserNotFound') {
      callNative(WebViewCallBacks.REPORT_ERROR, {error: ACCOUNT_SELECTION_USER_NOT_FOUND_ERROR});
      return {error: ACCOUNT_SELECTION_USER_NOT_FOUND_ERROR};
    }

    if (basicUser.memberStatus === AccountStatus.BLOCKED || basicUser.memberStatus === AccountStatus.ARCHIVED) {
      callNative(WebViewCallBacks.REPORT_ERROR, {error: ACCOUNT_SELECTION_INACTIVE_ACCOUNT_ERROR});
      return {error: ACCOUNT_SELECTION_INACTIVE_ACCOUNT_ERROR};
    }

    const firstSSOProfile = basicUser.ssoProfiles?.[0];

    const enteredEmailDomain = (accountInformation.user.email || accountInformation.user.username || '').split('@')[1];

    const checkAccountForSSOScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.SSO);
    const checkAccountForOTPScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.OTP);
    const checkAccountForPasswordScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.PASSWORD);

    const getOrgByDomain = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({
      domain: enteredEmailDomain,
    });

    if ('error' in getOrgByDomain) {
      callNative(WebViewCallBacks.REPORT_ERROR, {error: ACCOUNT_SELECTION_NO_MATCHED_ACCOUNT_ERROR});
      return {error: ACCOUNT_SELECTION_NO_MATCHED_ACCOUNT_ERROR};
    }

    let checkForSSOEmailOfOrg;

    if (getOrgByDomain.data.organizationForDomain) {
      checkForSSOEmailOfOrg = accountInformation.organization.id === getOrgByDomain.data.organizationForDomain.id;
    }

    if (checkForSSOEmailOfOrg) {
      if (checkAccountForSSOScope) {
        if (firstSSOProfile?.auth0Id && firstSSOProfile?.ssoVendor) {
          localStorage.setItem(SSO_VENDOR, firstSSOProfile?.ssoVendor);
          if (firstSSOProfile?.ssoVendor === AuthSSOVendors.WORKOS) {
            await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(
              firstSSOProfile?.auth0Id,
              firstSSOProfile?.ssoVendor,
            );
            return;
          } else {
            return {auth0Id: firstSSOProfile?.auth0Id, address: ''};
          }
        }
      }
    }

    if (checkAccountForOTPScope || checkAccountForPasswordScope) {
      if (basicUser.memberStatus === AccountStatus.INACTIVE) {
        const challengeId = await requestAddressOTP({address: userAddress, type: 'email'});
        if (typeof challengeId === 'string') {
          window.routerHistory.push(
            `/signup?email=${userAddress?.replace(
              '+',
              '%2b',
            )}&challengeId=${challengeId}&orgUrl=${orgUrl}&step=2&activateShellAccountFlow=true&orgName=${
              accountInformation.organization.name
            }`,
          );
          callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
        } else {
          return challengeId;
        }
      } else {
        if (checkAccountForOTPScope && checkAccountForPasswordScope) {
          const startRequestOTPFlow = await requestAddressOTP({address: userAddress, type: 'email'});

          if (typeof startRequestOTPFlow === 'string') {
            return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow, isMethodSwitchable: true};
          } else {
            return startRequestOTPFlow;
          }
        }

        if (checkAccountForOTPScope) {
          const startRequestOTPFlow = await requestAddressOTP({address: userAddress, type: 'email'});

          if (typeof startRequestOTPFlow === 'string') {
            return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
          } else {
            return startRequestOTPFlow;
          }
        }

        if (checkAccountForPasswordScope) {
          return {screen: OrgLoginMethods.PASSWORD};
        }
      }
    } else {
      if (!firstSSOProfile) {
        callNative(WebViewCallBacks.REPORT_ERROR, {error: ACCOUNT_SELECTION_ACCOUNT_HAS_NO_ORG_ERROR});
        return {error: ACCOUNT_SELECTION_ACCOUNT_HAS_NO_ORG_ERROR};
      }

      if (checkAccountForOTPScope && firstSSOProfile?.domain === enteredEmailDomain) {
        if (firstSSOProfile?.auth0Id && firstSSOProfile?.ssoVendor) {
          localStorage.setItem(SSO_VENDOR, firstSSOProfile?.ssoVendor);
          if (firstSSOProfile?.ssoVendor === AuthSSOVendors.WORKOS) {
            await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(
              firstSSOProfile?.auth0Id,
              firstSSOProfile?.ssoVendor,
            );
            return;
          } else {
            return {auth0Id: firstSSOProfile?.auth0Id, address: ''};
          }
        }
      } else {
        callNative(WebViewCallBacks.REPORT_ERROR, {error: ACCOUNT_SELECTION_NO_SUPPORTED_LOGIN_METHODS_ERROR});
        return {
          error: ACCOUNT_SELECTION_NO_SUPPORTED_LOGIN_METHODS_ERROR,
        };
      }
    }
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        callNative(WebViewCallBacks.REPORT_ERROR, {
          error: TOO_MANY_CHALLENGES_MSG,
        });
        return {
          error: TOO_MANY_CHALLENGES_MSG,
        };
      } else {
        callNative(WebViewCallBacks.REPORT_ERROR, {
          error: VALIDATION_CODE_FAILED_SEND_MSG,
        });
        return {
          error: VALIDATION_CODE_FAILED_SEND_MSG,
        };
      }
    }
  };

  return {
    handleLoginToClickedAccount,
    retrieveSuggestedOrganizations,
    suggestedOrganizationClick,
    handleSuggestedOrganizationFlow,
  };
};
