import React from 'react';
import {Formik, FormikHelpers, FormikValues} from 'formik';
import {Box, CircularProgress, IconButton, InputAdornment, LinearProgress} from '@material-ui/core';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import {StyledInner, StyledStepContainer} from '../styled/signup.styled';
import {LoginHeader} from './LoginHeader';
import {HelperBox, InputsWrapper, StyledAnchor, StyledSubmitAddressButton} from '../styled/login.styled';
import {CurrentSelectedOrganization} from '../types/login.types';
import * as Yup from 'yup';
import {VisibilityOffOutlined, VisibilityOutlined} from '@material-ui/icons';
import {HCTextSpanContextThree} from '../../components/HypercareComponents';
import {CONTACT_US, FORGOT_YOUR_PASSWORD, HAVING_SOME_ISSUES} from '../../constants/strings';
import {ContactUsFooter} from 'src/pages/LoginPage/sta/components/ContactUsFooter';
import {ProgressContainer} from '../../pages/LoginPage/styled/login.styled';

interface ILoginFormInputData {
  title: string;
  placeholder: string;
  label: string;
  name: string;
  dataTestId: string;
  description?: string;
}

export interface ILoginFormProps<LoginFormValues> {
  initialValues: LoginFormValues;
  validationSchema: Yup.SchemaOf<LoginFormValues>;
  onSubmit: (values: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>) => Promise<void>;
  children: ILoginFormInputData;
  handleGoBack: () => void;
  organization?: CurrentSelectedOrganization;
  error?: string;
  renderInput?: React.ReactNode;
  type: string;
  showPassword?: boolean;
  setShowPassword?: React.Dispatch<React.SetStateAction<boolean>>;
  showEndAdornment?: boolean;
  isLoading: boolean;
  isLoginMethodSwitchable: boolean;
  handleIsLoginMethodSwitchableClick: (type: string) => Promise<void>;
  hideBackButton?: boolean;
}

export const LoginForm = <LoginFormValues extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  handleGoBack,
  organization,
  error,
  renderInput,
  type,
  showPassword,
  setShowPassword,
  showEndAdornment,
  isLoading,
  isLoginMethodSwitchable,
  handleIsLoginMethodSwitchableClick,
  hideBackButton,
}: ILoginFormProps<LoginFormValues>) => {
  return (
    <Formik<LoginFormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting}) => (
        <form onSubmit={handleSubmit} style={{width: '100%', maxWidth: '600px'}}>
          <StyledStepContainer loading={isSubmitting ? 'true' : undefined}>
            {isSubmitting && (
              <ProgressContainer>
                <LinearProgress color="secondary" />
              </ProgressContainer>
            )}
            <StyledInner>
              <LoginHeader
                title={children.title}
                description={children.description}
                onBackButtonClick={handleGoBack}
                hideBackButton={hideBackButton}
                headerTitle={
                  <>
                    {organization && organization.imageURL ? (
                      <img height={50} src={organization?.imageURL} alt={organization?.name + 'logo'} />
                    ) : (
                      <>{organization?.name}</>
                    )}
                  </>
                }
              />
              <InputsWrapper>
                {renderInput ? (
                  renderInput
                ) : (
                  <StyledTextField
                    autoCapitalize={'off'}
                    data-testid={children.dataTestId}
                    fullWidth
                    type={showPassword ? 'text' : type}
                    variant="outlined"
                    placeholder={children.placeholder}
                    label={children.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={children.name}
                    value={values[children.name]}
                    helperText={error || (touched[children.name] && errors[children.name])}
                    error={!!error || (touched[children.name] && Boolean(errors[children.name]))}
                    InputProps={{
                      endAdornment: showEndAdornment && (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            onClick={() => setShowPassword && setShowPassword((prev) => !prev)}
                            style={{marginRight: -8}}
                          >
                            {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </InputsWrapper>
              <HelperBox>
                {isLoginMethodSwitchable && (
                  <HCTextSpanContextThree
                    style={{color: '#00859A', cursor: 'pointer'}}
                    onClick={() => handleIsLoginMethodSwitchableClick('password')}
                    padding={'4px'}
                  >
                    Login with {type === 'password' && 'a one-time code instead'}
                  </HCTextSpanContextThree>
                )}
              </HelperBox>
              <StyledSubmitAddressButton
                data-testid="submitButton"
                type="submit"
                disabled={isLoading || !values[children.name]}
              >
                {isLoading && (
                  <>
                    <CircularProgress color="inherit" size={18} style={{marginRight: 16}} /> {'Submitting Password'}
                  </>
                )}
                {!isLoading && 'Next'}
              </StyledSubmitAddressButton>
              <div>
                <HelperBox>
                  {type === 'email' ? (
                    <ContactUsFooter />
                  ) : (
                    <div style={{marginBottom: '8px', cursor: 'pointer'}}>
                      <HCTextSpanContextThree color={'#00859A'}>{FORGOT_YOUR_PASSWORD}</HCTextSpanContextThree>
                    </div>
                  )}
                </HelperBox>
              </div>
            </StyledInner>
          </StyledStepContainer>
        </form>
      )}
    </Formik>
  );
};
