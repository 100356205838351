import gql from 'graphql-tag';
import {OrganizationLoginMethods} from '../../../types';

export interface DomainOrganization {
  __typename: string;
  domains: string[];
  id: number;
  name: string;
  imageURL: string;
  loginMethods: OrganizationLoginMethods[];
  url: string;
}

export interface FetchOrganizationsByDomainsResponse {
  data: {
    organizationsForDomains: DomainOrganization[];
  };
}

export const FETCH_ORGANIZATIONS_BY_DOMAINS_QUERY = gql`
  query FindOrganizationsByDomains($domains: [String!]!) {
    organizationsForDomains(domains: $domains) {
      ...OrganizationFragment
    }
  }
  fragment OrganizationFragment on Organization {
    __typename
    domains
    name
    id
    loginMethods
    imageURL
    url
  }
`;
