import {HCClickFilter} from '../../../../microfrontend/components/HCClickFilter';
import {FilterItemContainer, FilterItemContent, OrganizationNoIconWrapper} from '../../styled/login.styled';
import {HCCustomSpan, HCHeadingFour} from '../../../../components/HypercareComponents';
import theme from '../../../../styles/theme';
import {ChevronRight} from '../../../../svgs/ChevronRight';
import React from 'react';
import {HCClickFilterOption} from './FindUserOrganizationView';

export interface OrganizationSearchViewProps {
  searchText: string;
  handleSearch: (searchValue: string) => void;
  isLoadingSearch: boolean;
  userOptions: HCClickFilterOption[];
  handleOnUserOptionClick: (option: HCClickFilterOption) => void;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  clearSearch: () => void;
}
export const OrganizationSearchView = ({
  searchText,
  handleSearch,
  isLoadingSearch,
  userOptions,
  handleOnUserOptionClick,
  setSearchText,
  clearSearch,
}: OrganizationSearchViewProps) => (
  <HCClickFilter
    onClear={clearSearch}
    searchText={searchText}
    handleSearch={handleSearch}
    isSearchLoading={isLoadingSearch}
    options={{title: 'Organizations', options: userOptions}}
    optionRender={(option) => (
      <FilterItemContainer onClick={() => handleOnUserOptionClick(option)}>
        <FilterItemContent>
          {option.imageURL ? (
            <img src={option.imageURL} height={32} width={32} alt={option.name} />
          ) : (
            <OrganizationNoIconWrapper>
              <HCHeadingFour color={theme.messageWhite}>{option.name[0]}</HCHeadingFour>
            </OrganizationNoIconWrapper>
          )}
          <HCCustomSpan color={theme.black}>{option.name}</HCCustomSpan>
        </FilterItemContent>
      </FilterItemContainer>
    )}
  />
);
