import React, {useContext, useEffect, useState} from 'react';

import {AccountSelectionView} from './login/AccountSelectionView';
import {FindUserOrganizationView} from './login/FindUserOrganizationView';
import {AuthContext} from '../../../auth';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {AuthPayloadDTO, Login, STALogin} from '../../../types';
import {
  DEBUG,
  DESIRED_ROUTE,
  ENCODED_DATA_FROM_MOBILE_CLIENTS,
  HYPERCARE_REGION,
  INTRO_DONE,
  MOBILE_CLIENT_ID,
  ORGANIZATION_ACCOUNTS_DATA,
  SELECTED_ACCOUNT,
  REDIRECT_URI,
} from '../../../constants/storageKeys';
import HypercareLogoSVG from '../../../svgs/HypercareLogoSVG';
import {CurrentSelectedOrganization} from '../../../microfrontend/types/login.types';
import {LoginEmailAddressView} from '../../../microfrontend/login/LoginEmailAddressView';
import {LoginPasswordView} from '../../../microfrontend/login/LoginPasswordView';
import {OrganizationViewModel} from './view-models/OrganizationViewModel';
import {
  CurrentLoginStep,
  OrganizationAccountsCacheData,
  OrganizationAccountsCacheDataFromMobile,
  OrganizationAccountsDataFromAdmin,
  OrgLoginMethods,
} from '../../../types/sta';
import {LoginOTPView} from '../../../microfrontend/login/LoginOTPView';
import {LoginPageHypercareLogoContainer, PageContainer} from '../styled/login.styled';
import {AccountDiscoveryCoordinator} from './accountDiscovery/AccountDiscoveryCoordinator';
import ChangeRegionModal from '../../../components/ChangeRegion/ChangeRegionModal';
import {LOGIN} from '../../../constants/strings';
import HypercareAuthRegionContext from '../../../contexts/HypercareLoginCoordinatorContext';
import {localStorageService} from '../../../services/localStorageService';
import {AccountDiscoveryViewModel} from './view-models/AccountDiscoveryViewModel';
import {AUTH_INTRO} from '../../../constants/routerPathName';
import {toast} from 'react-toastify';
import {AuthViewModel} from '../../../auth/AuthViewModel';
import {decodeBase64String, isAccessTokenValid, isRefreshTokenValid} from '../../../utils/sta/staUtils';
import {AccountSelectionViewModel} from './view-models/AccountSelectionViewModel';

import {STEP} from '../../../constants/queryParams';
import {Redirect} from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

interface ILoginPageCoordinatorProps {
  isLoggedIn: boolean;
  auth0props: Auth0ContextInterface;
  login: Login;
  STALogin: STALogin;
}

const LoginPageCoordinator = ({isLoggedIn, auth0props, STALogin}: ILoginPageCoordinatorProps) => {
  const decodedURI = decodeURIComponent(window.location.search);
  const params = new URLSearchParams(decodedURI);

  const stepFromParams = parseInt(params.get(STEP) || '');
  const redirectUri = params.get(REDIRECT_URI);
  const selectedAccountForLogin = params.get(SELECTED_ACCOUNT);

  const [load, setLoad] = useState(!!selectedAccountForLogin);

  const [currentStep, setCurrentStep] = useState<CurrentLoginStep>(
    stepFromParams ? stepFromParams : CurrentLoginStep.STEP_1,
  );
  const [currentSelectedOrg, setCurrentSelectedOrg] = useState<CurrentSelectedOrganization>({
    loginMethods: [],
    imageURL: 'https://hypercare.github.io/public-uploads-prod/organization_logos/hypercare_icon_v2-01.png',
    url: '',
    name: 'SickKids',
    id: '',
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [OTPError, setOTPError] = useState('');

  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const [challengeId, setChallengeId] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');

  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isLoginMethodSwitchable, setIsLoginMethodSwitchable] = useState(false);

  const {changeRegionModalOpenStatus, setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const [savedAccountsData, setSavedAccountsData] = useState<AuthPayloadDTO[]>(currentCacheData?.savedOrganizations);

  const {submitEmailAddress, submitOTP, submitPassword, handleOTPLogin} = OrganizationViewModel();
  const {handleSaveAccountsQueryParamToCache, handleResendOTP} = AccountDiscoveryViewModel();
  const {handleRefreshAccessToken} = AuthViewModel();
  const {handleLoginToClickedAccount} = AccountSelectionViewModel();

  if (redirectUri) {
    window.localStorage.setItem(REDIRECT_URI, redirectUri);
    window.localStorage.setItem(INTRO_DONE, '1');
  }
  const handleShowAccountDiscoveryFlow = () => {
    setCurrentStep(CurrentLoginStep.STEP_6);
  };

  const encodedDataFromMobile = localStorageService.getItem<OrganizationAccountsCacheDataFromMobile>(
    ENCODED_DATA_FROM_MOBILE_CLIENTS,
  );

  useEffect(() => {
    const getData = async (selectedAccountForLogin) => {
      localStorage.setItem(INTRO_DONE, '1');
      const {clientId, selectedAccount, regionCode}: OrganizationAccountsDataFromAdmin =
        decodeBase64String(selectedAccountForLogin);

      if (clientId) {
        localStorageService.setItem(MOBILE_CLIENT_ID, clientId);
      }

      if (regionCode) {
        localStorageService.setItem(HYPERCARE_REGION, regionCode);
      }

      if (selectedAccount) {
        console.log(currentCacheData, 'currentds');
        if (!currentCacheData?.savedOrganizations?.length) {
          console.log(selectedAccount, 'savedOrg');
          setIsEmailLoading(true);

          setCurrentSelectedOrg(selectedAccount.organization);
          setCurrentEmail(selectedAccount.user.email || '');

          const res = await submitEmailAddress(selectedAccount.user.email || '', selectedAccount.organization);
          console.log(res, 'res');

          if (res && 'error' in res) {
            setEmailError(res.error);
          }
          if (res && 'auth0Id' in res) {
            loginWithAuth0(res.auth0Id);
          }
          setIsEmailLoading(false);
          setLoad(false);

          switch (res.screen) {
            case OrgLoginMethods.OTP:
              setCurrentStep(CurrentLoginStep.STEP_5);
              res.challengeId && setChallengeId(res.challengeId);
              res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
              return;
            case OrgLoginMethods.PASSWORD:
              setCurrentStep(CurrentLoginStep.STEP_4);
              res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
              return;
            case OrgLoginMethods.LOGIN:
              STALogin(currentSelectedOrg, res.data, currentEmail);
              break;
            default:
              break;
          }
        } else {
          setLoad(false);
        }
      }
    };

    if (selectedAccountForLogin) {
      getData(selectedAccountForLogin);
    } else {
      setLoad(false);
    }
  }, []);

  useEffect(() => {
    if (!savedAccountsData?.length || currentCacheData?.savedOrganizations.length === 0 || stepFromParams) {
      handleShowAccountDiscoveryFlow();
    } else {
      setCurrentStep(CurrentLoginStep.STEP_1);
    }
  }, [savedAccountsData, changeRegionModalOpenStatus]);

  const handleLoginToAccount = async (savedOrg: AuthPayloadDTO) => {
    setIsEmailLoading(true);
    setCurrentSelectedOrg(savedOrg.organization);

    const res = await handleLoginToClickedAccount(savedOrg);

    if (res?.error) {
      toast.error(res.error);
    }
    if (res?.auth0Id) {
      loginWithAuth0(res.auth0Id);
    }

    switch (res?.screen) {
      case OrgLoginMethods.OTP:
        res.challengeId && setChallengeId(res.challengeId);
        setCurrentEmail(savedOrg.user.email || savedOrg.user.username || '');
        handleShowOtpView(res.isMethodSwitchable && true);
        return;
      case OrgLoginMethods.PASSWORD:
        setCurrentEmail(savedOrg.user.email || savedOrg.user.username || '');
        handleShowPasswordView(res.isMethodSwitchable && true);
        return;
      default:
        break;
    }
    setIsEmailLoading(false);
  };

  useEffect(() => {
    if (encodedDataFromMobile) {
      handleSaveAccountsQueryParamToCache(encodedDataFromMobile);
      localStorage.setItem(INTRO_DONE, '1');
      encodedDataFromMobile.clientId && localStorageService.setItem(MOBILE_CLIENT_ID, encodedDataFromMobile.clientId);
      return;
    }

    if (window.localStorage.getItem(INTRO_DONE) !== '1') {
      window.routerHistory.push(`/${AUTH_INTRO}`);
    }
  }, []);

  useEffect(() => {
    if (
      currentCacheData &&
      currentCacheData?.savedOrganizations?.length >= 1 &&
      currentCacheData?.selectedAccountUserId
    ) {
      const findAccount = currentCacheData.savedOrganizations.find(
        (acc) => acc.user.id === currentCacheData.selectedAccountUserId,
      );
      if (findAccount && !redirectUri) STALogin(findAccount.organization, findAccount, findAccount.user.email || '');
    }
  }, []);

  useEffect(() => {
    const refreshTokenFlow = async () => {
      if (currentCacheData?.savedOrganizations && currentCacheData?.savedOrganizations?.length > 0) {
        for (const acc of currentCacheData.savedOrganizations) {
          if (!isAccessTokenValid(acc) && isRefreshTokenValid(acc)) {
            await handleRefreshAccessToken(acc.refreshToken || '', acc.user.id);
          }
        }
      }
    };

    refreshTokenFlow();
  }, []);

  if (isLoggedIn) {
    const desiredRoute = sessionStorage.getItem(DESIRED_ROUTE);
    return <Redirect to={desiredRoute ? desiredRoute : '/messenger/home'} />;
  }

  const handleGoToPreviousStep = () => {
    setEmailError('');
    setPasswordError('');
    setIsEmailLoading(false);
    setIsPasswordLoading(false);

    if (savedAccountsData?.length > 0) {
      setCurrentStep(CurrentLoginStep.STEP_1);
    } else {
      setCurrentStep(CurrentLoginStep.STEP_6);
    }
  };

  const showDebugMenu = () => {
    window.routerHistory.replace(`/${DEBUG}`);
  };

  const handleSubmitPassword = async (value: string) => {
    setIsPasswordLoading(true);
    const res = await submitPassword(currentSelectedOrg.url || '', currentEmail, value);

    if (res?.error) {
      setPasswordError(res.error);
      setIsPasswordLoading(false);
    }
    if (res?.data) {
      STALogin(currentSelectedOrg, res.data, currentEmail);
    }
  };

  const handleSubmitEmailAddress = async (value: string) => {
    setIsEmailLoading(true);
    setCurrentEmail(value);

    const res = await submitEmailAddress(value, currentSelectedOrg);

    if (res && 'error' in res) {
      setEmailError(res.error);
    }
    if (res && 'auth0Id' in res) {
      loginWithAuth0(res.auth0Id);
    }
    setIsEmailLoading(false);

    switch (res.screen) {
      case OrgLoginMethods.OTP:
        setCurrentStep(CurrentLoginStep.STEP_5);
        res.challengeId && setChallengeId(res.challengeId);
        res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
        return;
      case OrgLoginMethods.PASSWORD:
        setCurrentStep(CurrentLoginStep.STEP_4);
        res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
        return;
      case OrgLoginMethods.LOGIN:
        STALogin(currentSelectedOrg, res.data, currentEmail);
        break;
      default:
        break;
    }
  };

  const loginWithAuth0 = (auth0Id: string) => {
    auth0props.loginWithRedirect({
      connection: auth0Id,
      scope: 'openid profile email',
    });
  };

  const handleSubmitOTP = async (value: string) => {
    setIsOTPLoading(true);
    const res = await submitOTP(challengeId, value, currentSelectedOrg.url || '');

    if (res?.error || 'error' in res) {
      setOTPError(res.error);
    }

    if (res.data) {
      STALogin(currentSelectedOrg, res.data, currentEmail);
    }
    setIsOTPLoading(false);
  };

  const resendOTPCode = async (email: string) => {
    const res = await handleResendOTP(email);

    if (res?.challengeId) {
      setChallengeId(res.challengeId);
      toast.success(`Successfully resent verification code`);
    }

    if (res?.error) {
      toast.error('Unable to resend verification code');
    }
  };

  const handleIsLoginMethodSwitchableClick = async (type: string) => {
    if (type !== 'password') {
      setCurrentStep(CurrentLoginStep.STEP_4);
    } else {
      const ers = await handleOTPLogin(currentEmail);
      setCurrentStep(CurrentLoginStep.STEP_5);
    }
  };
  const showEmailAddressView = () => {
    return (
      <LoginEmailAddressView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitEmailAddress}
        organization={currentSelectedOrg}
        error={emailError}
        isLoading={isEmailLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const showPasswordView = () => {
    return (
      <LoginPasswordView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitPassword}
        organization={currentSelectedOrg}
        error={passwordError}
        isLoading={isPasswordLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const showOTPView = () => {
    return (
      <LoginOTPView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitOTP}
        organization={currentSelectedOrg}
        resend={resendOTPCode}
        isResending={isResendingOTP}
        error={OTPError}
        currentSelectedEmail={currentEmail}
        isLoading={isOTPLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const handleShowEmailView = () => {
    setCurrentStep(CurrentLoginStep.STEP_3);
  };
  const handleShowPasswordView = (isMethodSwitchable?: boolean) => {
    setCurrentStep(CurrentLoginStep.STEP_4);
    isMethodSwitchable && setIsLoginMethodSwitchable(true);
  };

  const handleShowOtpView = (isMethodSwitchable?: boolean) => {
    setCurrentStep(CurrentLoginStep.STEP_5);
    isMethodSwitchable && setIsLoginMethodSwitchable(true);
  };

  const showAccountDiscovery = () => {
    return <AccountDiscoveryCoordinator showFindMyOrgView={() => setCurrentStep(CurrentLoginStep.STEP_2)} />;
  };

  const renderCurrentStep = (currentStep: CurrentLoginStep) => {
    switch (currentStep) {
      case CurrentLoginStep.STEP_1:
        return (
          <AccountSelectionView
            handleNextStep={handleShowAccountDiscoveryFlow}
            STALogin={STALogin}
            showOtpView={handleShowOtpView}
            showPasswordView={handleShowPasswordView}
            loginWithAuth0={loginWithAuth0}
            setEmail={setCurrentEmail}
            setCurrentChallengeId={setChallengeId}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            savedAccountsData={savedAccountsData}
            setSavedAccountsData={setSavedAccountsData}
          />
        );
      case CurrentLoginStep.STEP_2:
        return (
          <FindUserOrganizationView
            handleGoBack={handleGoToPreviousStep}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            handleShowEmailView={handleShowEmailView}
            handleShowPasswordView={handleShowPasswordView}
          />
        );
      case CurrentLoginStep.STEP_3:
        return showEmailAddressView();
      case CurrentLoginStep.STEP_4:
        return showPasswordView();
      case CurrentLoginStep.STEP_5:
        return showOTPView();
      case CurrentLoginStep.STEP_6:
        return showAccountDiscovery();

      default:
        return (
          <AccountSelectionView
            savedAccountsData={savedAccountsData}
            handleNextStep={handleShowAccountDiscoveryFlow}
            STALogin={STALogin}
            showOtpView={handleShowOtpView}
            showPasswordView={handleShowPasswordView}
            loginWithAuth0={loginWithAuth0}
            setCurrentChallengeId={setChallengeId}
            setEmail={setCurrentEmail}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            setSavedAccountsData={setSavedAccountsData}
          />
        );
    }
  };

  if (load) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <LoginPageHypercareLogoContainer onClick={showDebugMenu}>
        <HypercareLogoSVG />
      </LoginPageHypercareLogoContainer>

      {renderCurrentStep(currentStep)}
      {changeRegionModalOpenStatus && (
        <ChangeRegionModal
          isOpen={changeRegionModalOpenStatus}
          setIsOpen={setChangeRegionModalOpenStatus}
          currentScreen={LOGIN}
          setSavedAccountsData={setSavedAccountsData}
        />
      )}
    </PageContainer>
  );
};

export const HypercareLoginPageCoordinator = () => {
  return (
    <AuthContext.Consumer>
      {({isLoggedIn, auth0props, login, STALogin}) => (
        <LoginPageCoordinator STALogin={STALogin} isLoggedIn={isLoggedIn} auth0props={auth0props} login={login} />
      )}
    </AuthContext.Consumer>
  );
};
