import gql from 'graphql-tag';
import {BasicUser} from '../../../types/sta';

export type FetchBasicUserResponse = {
  data: {
    user: BasicUser;
  };
};

export const FETCH_BASIC_USER_QUERY = gql`
  query FetchBasicUser($email: String!, $organizationUrl: String!) {
    user(email: $email, organizationUrl: $organizationUrl) {
      ... on Error {
        __typename
        message
      }
      ... on PublicUser {
        id
        firstName
        avatar {
          url
        }
        lastName
        memberStatus
        loginScopes
        ssoProfiles {
          organization {
            name
            imageURL
          }
          auth0Id
          ssoId
          ssoVendor
          domain
        }
      }
    }
  }
`;
