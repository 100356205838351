import axios from 'axios';
import * as qs from 'qs';
import client from 'src/apollo';
import {pick} from 'ramda';
import {AuthPayload, AuthPayloadDTO, AuthSSOVendors, LoginResponse, OrganizationScope} from 'src/types';
import getApiEndpoint from 'src/utils/getApiEndpoint';
import AnalyticsManager from 'src/analytics/AnalyticsManager';
import getParsedAuthInfo, {updateLocalStorageTokenInfo} from 'src/utils/localStorageHandler';
import ensureChatListLoaded from 'src/utils/messengerHelper/ensureChatListLoaded';
import {v4 as uuid} from 'uuid';
import {ALLOWED_LOCAL_STORAGE_USER_KEY_LIST} from 'src/constants/user';
import ApiHelper from 'src/api';
import {
  AUTH_INFO,
  HYPERCARE_REGION,
  HYPERCARE_USERNAME,
  ORGANIZATION_ACCOUNTS_DATA,
  SESSION_ID,
} from 'src/constants/storageKeys';
import FirebaseNotificationController from 'src/notifications/FirebaseNotificationManager';
import {
  ExchangeOTPTokenForPartialAccessTokenResponse,
  OrgLoginMethods,
  RefreshTokenRequestResponse,
  TokenExchangeGrantRequestResponse,
} from '../types/sta';
import {UNKNOWN_ERROR_STRING} from 'src/constants/networkError';
import {getAuthClientId} from '../utils/sta/staUtils';

const HYPERCARE_AUTH_TOKEN = 'Basic ZGVtb2NsaWVudDpkZW1vY2xpZW50c2VjcmV0';
const CONTENT_TYPE = 'application/x-www-form-urlencoded;charset=UTF-8';

class AuthHelper {
  public static getAuthEndpoint() {
    return `${getApiEndpoint()}/oauth/token`;
  }
  public static getAuthTokenEndpoint() {
    return `${getApiEndpoint()}/authenticate/auth0/adfs/token`;
  }

  private static getCommonHeaders() {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Request-ID': uuid(),
    };
  }

  public static async refreshAccessToken() {
    const refreshData = qs.stringify({
      grant_type: 'refresh_token',
      refresh_token: ApiHelper.PrivateEndpoints.getAuthToken('refreshToken'),
    });
    const headers = {
      headers: {
        Authorization: HYPERCARE_AUTH_TOKEN,
        'Content-Type': CONTENT_TYPE,
        'X-Request-ID': uuid(),
      },
    };

    try {
      const result = await axios.post(this.getAuthEndpoint(), refreshData, headers);
      const authInfo = result.data.response || result.data;
      const resultResponse: LoginResponse = {
        data: authInfo,
        success: true,
      };
      updateLocalStorageTokenInfo(resultResponse.data);
      return Promise.resolve(resultResponse);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public static async fetchAuthInfo(username: string, password: string): Promise<LoginResponse> {
    const loginData = qs.stringify({
      grant_type: 'password',
      password,
      username,
    });

    const headers = {
      headers: {
        Authorization: HYPERCARE_AUTH_TOKEN,
        'Content-Type': CONTENT_TYPE,
      },
    };

    try {
      const result = await axios.post(this.getAuthEndpoint(), loginData, headers);
      const authInfo = result.data.response || result.data;
      return {
        data: authInfo,
        success: true,
      };
    } catch (e) {
      console.error(e);
      this.clearLocalStorage(username);
      return {
        success: false,
        error: e?.response?.data?.errors?.[0] ?? UNKNOWN_ERROR_STRING,
      };
    }
  }

  public static clearLocalStorage(username?: string) {
    const parsedAuthInfo = getParsedAuthInfo();
    const paredOrgCacheData = localStorage.getItem(ORGANIZATION_ACCOUNTS_DATA);
    const localStoredUsername = localStorage.getItem(HYPERCARE_USERNAME);
    const localStoredRegion = localStorage.getItem(HYPERCARE_REGION);

    // localStorage.clear();

    localStorage.removeItem(AUTH_INFO);
    if (localStoredRegion) {
      localStorage.setItem(HYPERCARE_REGION, localStoredRegion);
    }

    if (username) {
      localStorage.setItem(HYPERCARE_USERNAME, username);
    } else if (parsedAuthInfo) {
      localStorage.setItem(HYPERCARE_USERNAME, parsedAuthInfo.user.username as string);
    } else if (localStoredUsername) {
      localStorage.setItem(HYPERCARE_USERNAME, localStoredUsername);
    } else if (paredOrgCacheData) {
      localStorage.setItem(ORGANIZATION_ACCOUNTS_DATA, JSON.stringify(paredOrgCacheData));
    }
  }

  public static logout() {
    // apollo
    client.clearStore();
    // analytics
    AnalyticsManager.resetMixpanel();
    // intercom
    this.shutdownIntercom();
    // app
    this.clearLocalStorage();

    FirebaseNotificationController.unsubscribeToPushNotifications();

    sessionStorage.removeItem(SESSION_ID);
  }

  public static async setAuthScope(organizationScope: OrganizationScope) {
    if (organizationScope) {
      try {
        const userOrganizationScope = await this.getInitialScope(organizationScope);
        const parsedAuthInfo = getParsedAuthInfo();
        const userScopeString = JSON.stringify(userOrganizationScope);
        const getScopeToken = btoa(userScopeString);
        const sessionID = uuid();
        parsedAuthInfo!.scopeToken = getScopeToken;
        parsedAuthInfo!.sessionID = sessionID;
        localStorage.setItem(AUTH_INFO, JSON.stringify(parsedAuthInfo));
        sessionStorage.setItem(SESSION_ID, sessionID);
      } catch (e) {
        console.error(e);
      }
    }
  }

  public static setUpThirdParty(authClone: AuthPayload) {
    /* mixpanel */
    AnalyticsManager.mixpanelLoginEvent(authClone);
    /* Intercom - tour */
    this.connectToIntercom(authClone);
  }

  public static saveAuthToLocalStorage(authInfo: AuthPayload | AuthPayloadDTO) {
    authInfo.user = pick(ALLOWED_LOCAL_STORAGE_USER_KEY_LIST, authInfo.user);
    const allowedKey = ['accessToken', 'accessTokenExpiresAt', 'refreshToken', 'refreshTokenExpiresAt', 'user'];
    const filteredAuthInfo = pick(allowedKey, authInfo);
    localStorage.setItem(AUTH_INFO, JSON.stringify(filteredAuthInfo));
  }

  public static getAuth0RequestData(token: string) {
    const requestData = qs.stringify({
      token,
      grant_type: 'auth0_sso_grant',
    });

    const config = {
      headers: {
        Authorization: HYPERCARE_AUTH_TOKEN,
        ...this.getCommonHeaders(),
      },
    };

    return {
      requestData,
      config,
    };
  }

  public static getCodeGrantRequestData(token: string, provider: AuthSSOVendors) {
    const requestData = qs.stringify({
      code: token,
      grant_type: 'authorization_code',
      client_id: getAuthClientId(),
      redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
      provider,
    });

    const config = {
      headers: this.getCommonHeaders(),
    };

    return {
      requestData,
      config,
    };
  }

  public static async fetchSSOAccessToken(token: string, provider: AuthSSOVendors) {
    try {
      const {requestData, config} =
        provider === AuthSSOVendors.AUTH0
          ? this.getAuth0RequestData(token)
          : this.getCodeGrantRequestData(token, AuthSSOVendors.WORKOS);

      const result = await axios.post(this.getAuthTokenEndpoint(), requestData, config);
      const authInfo = result.data.response || result.data;
      return {
        success: true,
        data: authInfo,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async exchangeSSOCodeForPartialAccessToken(token: string, provider: AuthSSOVendors) {
    let url = 'https://auth.staging.hypercare.com:8443/oauth/v1/token';

    try {
      const urlencoded = new URLSearchParams();
      const myHeaders = new Headers();

      if (provider === AuthSSOVendors.AUTH0) {
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
        myHeaders.append('Authorization', HYPERCARE_AUTH_TOKEN);

        urlencoded.append('grant_type', 'auth0_sso_grant');
        urlencoded.append('token', token);
      } else {
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        urlencoded.append('grant_type', 'authorization_code');
        urlencoded.append('code', token);
        urlencoded.append('client_id', getAuthClientId());
        urlencoded.append('redirect_uri', `${process.env.REACT_APP_AUTH_REDIRECT_URI}`);
        urlencoded.append('provider', provider);
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: ExchangeOTPTokenForPartialAccessTokenResponse = await response.json();
      return {data, error: false};
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async exchangeOTPTokenForPartialAccessToken(challengeId: string) {
    let url = 'https://auth.staging.hypercare.com:8443/oauth/v1/token';
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'urn:ietf:params:oauth:grant-type:mfa-otp');
    urlencoded.append('challenge_id', challengeId);
    urlencoded.append('client_id', getAuthClientId());

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    try {
      const res = await fetch(url, requestOptions);
      const data: ExchangeOTPTokenForPartialAccessTokenResponse = await res.json();
      return {data, error: false};
    } catch (err) {
      return {data: null, error: err};
    }
  }

  public static async exchangeToken(orgUrl: string, accessToken: string, scope?: OrgLoginMethods) {
    let subjectToken = `${orgUrl}:${accessToken}`;

    let url = 'https://auth.staging.hypercare.com:8443/oauth/v1/token';

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    myHeaders.append('Authorization', HYPERCARE_AUTH_TOKEN);

    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange');
    urlencoded.append('subject_token', btoa(subjectToken));
    urlencoded.append('provider', 'hypercare');
    urlencoded.append('client_id', getAuthClientId());

    switch (scope) {
      case OrgLoginMethods.OTP:
        urlencoded.append('scope', `login:otp`);
        break;
      case OrgLoginMethods.SSO:
        urlencoded.append('scope', `login:sso`);
        break;
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    try {
      const res = await fetch(url, requestOptions);
      const data: TokenExchangeGrantRequestResponse = await res.json();
      return {data, error: false};
    } catch (err) {
      console.error(err);
      return {data: null, error: err};
    }
  }

  public static async fetchAuthInfoWithEmailAndPassword(orgUrl: string, email: string, password: string) {
    let url = 'https://auth.staging.hypercare.com:8443/oauth/v1/token';

    const usernameString = `${orgUrl}:${email}`;

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'password');
    urlencoded.append('username', btoa(usernameString));
    urlencoded.append('password', password);
    urlencoded.append('client_id', getAuthClientId());

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      return {data, error: false};
    } catch (err) {
      console.error(err);
      return {data: null, error: err};
    }
  }

  // only organization id scope for client webapp
  private static async getInitialScope(organization: OrganizationScope) {
    const userScope = {
      organizationId: organization.id,
    };
    return userScope;
  }

  private static async connectToIntercom(authClone: AuthPayload) {
    await ensureChatListLoaded(3000);
    // TODO: setup intercom manager handle async boot for each individual page?
    // e.g. [Use your tour everywhere] feature will be invalid as no login event happen when page mount

    let INTERCOM_APP_ID;
    if (process.env.REACT_APP_ENV === 'production') {
      INTERCOM_APP_ID = 'dkvoyj1l';
    } else {
      INTERCOM_APP_ID = 'njhiahrn';
    }
    const {user} = authClone;
    // @ts-ignore
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      user_id: user.id,
      updated_at: new Date().getTime(),
      hide_default_launcher: true,
    });
  }

  public static async refreshAccessTokenRequest(refreshToken: string): Promise<RefreshTokenRequestResponse> {
    let url = 'https://auth.staging.hypercare.com:8443/oauth/v1/token';

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('grant_type', 'refresh_token');
    urlencoded.append('refresh_token', refreshToken);
    urlencoded.append('client_id', getAuthClientId());

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded.toString(),
    };

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      return {data};
    } catch (err) {
      console.error(err);
      return {error: err as string};
    }
  }

  private static shutdownIntercom() {
    // @ts-ignore
    window.Intercom('shutdown');
  }
}

export default AuthHelper;
