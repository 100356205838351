import React from 'react';
import Closemark from 'src/svgs/Closemark';
import {StyledBox, BackButton, CloseButton} from '../styled/signup.styled';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {HCHeadingTwo, HCLabelOne} from 'src/components/HypercareComponents';
import theme from 'src/styles/theme';

interface ISignupHeaderProps {
  title?: string;
  isDisabled?: boolean;
  description?: string | JSX.Element;
  onBackButtonClick?: () => void;
  discard?: boolean;
  hideBackButton?: boolean;
}

const SignupHeader = (props: ISignupHeaderProps) => {
  return (
    <StyledBox>
      {!props.isDisabled && !props.discard && !props.hideBackButton ? (
        <BackButton onClick={props.onBackButtonClick}>
          <ArrowBackIcon />
        </BackButton>
      ) : null}
      {!props.isDisabled && props.discard ? (
        <CloseButton aria-label="close" onClick={props.onBackButtonClick}>
          <Closemark />
        </CloseButton>
      ) : null}
      <HCHeadingTwo color={theme.black} margin={'16px 0 16px 0'}>
        {props.title}
      </HCHeadingTwo>
      <HCLabelOne margin={'0 0 16px 0'}>{props.description}</HCLabelOne>
    </StyledBox>
  );
};

SignupHeader.defaultProps = {
  title: 'Title',
};

export default SignupHeader;
