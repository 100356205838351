import React from 'react';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {Chat} from 'src/types';
import styled from 'styled-components';
import LockIcon from 'src/svgs/LockIcon';
import {ApolloQueryResult} from 'apollo-client';
import MessagesInputFilePreview from './messages-layout/input-section/MessagesInputFilePreview';
import InputReplyMessagePreview from './messages-layout/input-section/InputReplyMessagePreview';
import MessagesInputArea from './messages-layout/input-section/MessagesInputArea';

interface Props {
  flags?: {[key: string]: boolean};
  chat?: Chat | any;
  chatId?: string;
  isSingleChat?: boolean;
  refetch: (variables?: Record<string, any>) => Promise<ApolloQueryResult<any>>;
  setIsSearch: (isSearch) => void;
  clearSearchOnNewMessage: () => void;
  jumpToLatestMessageOnSearch?: boolean;
}

const MessageContainerWrapper = styled.div`
  width: 100%;
  height: 90px;
  padding: 19px 32px 19px 32px;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15);
`;

const LockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 52px;
  left: 32px;
  top: 19px;
  background: #ffd8dd;
`;

const LockContainerContent = styled.div`
  width: fit-content;
  height: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #222222;
  flex: none;
  order: 1;
  flex-grow: 0;
  gap: 2px;
  a {
    color: black;
    font-family: 'Open Sans';
  }
`;

export class ChatBodyMessageInputSection extends React.Component<Props> {
  render() {
    return this.props.chat.status === 'locked' ? (
      <MessageContainerWrapper>
        <LockWrapper>
          <LockIcon width="16px" height="16px" data-testid="lock-conversation-lockicon" />{' '}
          <LockContainerContent>
            <b data-testid="lock-conversation-text">This conversation has been locked.</b>{' '}
            <a
              href="https://support.hypercare.com/hc/en-us/articles/8459087953549"
              target="_blank"
              data-testid="lock-conversation-learnmore"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </LockContainerContent>
        </LockWrapper>
      </MessageContainerWrapper>
    ) : (
      <React.Fragment>
        <MessagesInputFilePreview />
        <InputReplyMessagePreview chatId={this.props.chatId} />
        <MessagesInputArea
          chatId={this.props.chatId}
          isSingleChat={this.props.isSingleChat}
          jumpToLatestMessageOnSearch={this.props.jumpToLatestMessageOnSearch}
          refetch={this.props.refetch}
          setIsSearch={this.props.setIsSearch}
          clearSearchOnNewMessage={this.props.clearSearchOnNewMessage}
        />
      </React.Fragment>
    );
  }
}

export default withLDConsumer()(ChatBodyMessageInputSection);
