import axios from 'axios';
import {v4 as uuid} from 'uuid';
import getParsedAuthInfo, {getParsedAuthRegion} from 'src/utils/localStorageHandler';
import getApiEndpoint from 'src/utils/getApiEndpoint';
import AcceptEulaMutation from 'src/gql/mutation/AcceptEulaMutation';
import {REQUEST_ADDRESS_VERIFICATION} from 'src/gql/mutation/RequestAddressVerificationPrivate';
import JoinOrganizationMutation from 'src/gql/mutation/JoinOrganizationMutation';
import JoinOrganizationByInviteCode from 'src/gql/mutation/JoinOrganizationByInviteCode';
import CreateOrganizationMutation from 'src/gql/mutation/CreateOrganizationMutation';
import AcceptOrganizationTOSMutation from 'src/gql/mutation/AcceptOrganizationTOSMutation';
import {print} from 'graphql/language/printer';
import {
  VerifyAddressPayload,
  JoinOrganizationPayload,
  pushTokenPayload,
  deviceIdPayload,
  LocatingDepartmentScope,
  fetchDepartmentsAndRolesMiddlewareAPIRequestPayload,
  AuthSSOVendors,
  RequestVerificationPayload,
} from 'src/types';
import RegisterPushToken from 'src/gql/mutation/RegisterPushTokenMutation';
import AcknowledgePushMutation from 'src/gql/mutation/AcknowledgePushMutation';
import * as qs from 'qs';
import RegisterDeviceId from 'src/gql/mutation/RegisterDeviceMutation';
import UnregisterPushTokenMutation from 'src/gql/mutation/UnregisterPushTokenMutation';
import {CANADA_ABBREVIATION, EUROPE_ABBREVIATION, UNITED_STATES_ABBREVIATION} from 'src/constants/strings';
import moment from 'moment-timezone';
import {ADD_ADDRESS_MUTATION} from 'src/gql/v2/mutation/AddAddressMutation';
import {GetAccountsForEmailResponse, ValidateAddressVerificationResponse} from '../types/sta';
import {FETCH_ACCOUNTS_QUERY} from '../gql/v2/query/FetchAccounts';
import {
  FETCH_ORGANIZATION_BY_DOMAIN_QUERY,
  FetchOrganizationByDomainResponse,
} from '../gql/v2/query/FetchOrganizationByDomain';
import {getAuthClientId} from '../utils/sta/staUtils';

class PrivateEndpoints {
  public static getPrivateEndpoint() {
    return `${getApiEndpoint()}/graphql/private`;
  }

  public static getPrivateEndpointV2() {
    return `${getApiEndpoint()}/v2/graphql/private`;
  }

  public static getNotificationServiceApiEndpoint() {
    const region = getParsedAuthRegion();
    let endPointUrl = '';

    switch (region) {
      case CANADA_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_WS_URL;
        break;
      case UNITED_STATES_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_WS_URL_US;
        break;
      case EUROPE_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_WS_URL_EU;
        break;
      default:
        endPointUrl = process.env.REACT_APP_WS_URL;
        break;
    }

    return endPointUrl;
  }

  public static getMiddleWareApiEndpoint() {
    const region = getParsedAuthRegion();
    let endPointUrl = '';

    switch (region) {
      case CANADA_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_MIDDLEWARE_API_URL;
        break;
      case UNITED_STATES_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_MIDDLEWARE_API_URL_US;
        break;
      case EUROPE_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_MIDDLEWARE_API_URL_EU;
        break;
      default:
        endPointUrl = process.env.REACT_APP_MIDDLEWARE_API_URL;
        break;
    }

    return endPointUrl;
  }

  public static getOAuthURL() {
    const region = getParsedAuthRegion();
    let endPointUrl = '';

    switch (region) {
      case CANADA_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_AUTH_URL_CA;
        break;
      case UNITED_STATES_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_AUTH_URL_US;
        break;
      case EUROPE_ABBREVIATION:
        endPointUrl = process.env.REACT_APP_API_URL_EU;
        break;
      default:
        endPointUrl = process.env.REACT_APP_AUTH_URL_CA;
        break;
    }

    return `${endPointUrl}:8443/oauth/v1`;
  }

  public static getAuthToken(tokenType: 'accessToken' | 'refreshToken') {
    const parsedAuthInfo = getParsedAuthInfo();
    const token = parsedAuthInfo ? parsedAuthInfo[tokenType] : '';
    return token;
  }

  public static getPrivateHeaders() {
    const parsedAuthInfo = getParsedAuthInfo();
    const {scopeToken = ''} = parsedAuthInfo || {};

    return {
      Authorization: `Bearer ${this.getAuthToken('accessToken')}`,
      'X-Request-ID': uuid(),
      'hc-timezone': moment.tz.guess(),
      'X-Timezone': moment.tz.guess(),
      'Hypercare-scope': scopeToken,
    };
  }

  public static async uploadFile(formData: FormData) {
    const headers = this.getPrivateHeaders();
    try {
      const result = await axios.post(`${getApiEndpoint()}/upload_files`, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
        // TODO: upload progression
        // onUploadProgress: progressEvent => {
        //   console.log(progressEvent)
        //   console.log(progressEvent.loaded)
        // }
      });
      return result;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public static async acceptEula(accessToken: string) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'X-Request-ID': uuid(),
    };
    try {
      const result = await axios({
        url: this.getPrivateEndpoint(),
        method: 'post',
        headers,
        data: {
          query: print(AcceptEulaMutation),
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async requestAddressVerification(payload: VerifyAddressPayload) {
    const headers = this.getPrivateHeaders();
    try {
      const result = await axios({
        url: this.getPrivateEndpoint(),
        method: 'post',
        headers,
        data: {
          query: print(REQUEST_ADDRESS_VERIFICATION),
          variables: payload,
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async requestJoinOrganizationViaInviteCode(code: string) {
    const headers = this.getPrivateHeaders();
    try {
      const result = await axios({
        url: this.getPrivateEndpoint(),
        method: 'post',
        headers,
        data: {
          query: print(JoinOrganizationByInviteCode),
          variables: {code},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async requestJoinOrganization(payload: JoinOrganizationPayload) {
    const {organizationId} = payload;
    const headers = this.getPrivateHeaders();
    try {
      const result = await axios({
        url: this.getPrivateEndpoint(),
        method: 'post',
        headers,
        data: {
          query: print(JoinOrganizationMutation),
          variables: {
            organizationId,
          } as JoinOrganizationPayload,
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async createOrganization({name, domain}: {name: string; domain?: string}) {
    const headers = this.getPrivateHeaders();
    try {
      const result = await axios({
        url: this.getPrivateEndpoint(),
        method: 'post',
        headers,
        data: {
          query: print(CreateOrganizationMutation),
          variables: {
            domain,
            organizationDetails: {
              name,
            },
          },
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async acceptingTOS(organizationId: string | number) {
    const headers = this.getPrivateHeaders();
    try {
      const result = await axios({
        url: this.getPrivateEndpoint(),
        method: 'post',
        headers,
        data: {
          query: print(AcceptOrganizationTOSMutation),
          variables: {
            organizationId,
          },
        },
      });
      const {data} = result.data;

      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async addUserAddress(challengeId) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${this.getAuthToken('accessToken')}`);

    var graphql = JSON.stringify({
      query: print(ADD_ADDRESS_MUTATION),
      variables: {challengeId, addressDetails: {}},
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    return fetch(this.getPrivateEndpointV2(), requestOptions)
      .then((response) => response.json())
      .then((data) => {
        return {
          data,
        };
      })
      .catch((error) => console.error('error', error));
  }

  public static async registerPushNotification(payload: pushTokenPayload) {
    try {
      const result = await axios({
        url: this.getPrivateEndpointV2(),
        method: 'post',
        headers: this.getPrivateHeaders(),
        data: {
          query: print(RegisterPushToken),
          variables: {...payload},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async unRegisterPushNotification(payload) {
    try {
      const result = await axios({
        url: this.getPrivateEndpointV2(),
        method: 'post',
        headers: this.getPrivateHeaders(),
        data: {
          query: print(UnregisterPushTokenMutation),
          variables: {...payload},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async acknowledgePushNotificationReceived(payload) {
    try {
      const result = await axios({
        url: this.getPrivateEndpoint(),
        method: 'post',
        headers: this.getPrivateHeaders(),
        data: {
          query: print(AcknowledgePushMutation),
          variables: {...payload},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async registerDeviceId(payload: deviceIdPayload) {
    try {
      const result = await axios({
        url: this.getPrivateEndpointV2(),
        method: 'post',
        headers: this.getPrivateHeaders(),
        data: {
          query: print(RegisterDeviceId),
          variables: {...payload},
        },
      });
      const {data} = result.data;
      return {
        success: true,
        data,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error,
      };
    }
  }

  public static async fetchDepartmentsAndRolesMiddlewareAPIRequest({
    siteId,
    startDate,
    endDate,
  }: fetchDepartmentsAndRolesMiddlewareAPIRequestPayload): Promise<{
    success: boolean;
    errorMessage: null | boolean;
    data: fetchDepartmentsAndRolesMiddlewareAPIResponse | null;
  }> {
    const queryParams = {
      siteId,
      startDate,
      endDate,
    };

    const queryString = qs.stringify(queryParams);

    const url = `${this.getMiddleWareApiEndpoint()}/oncall/shifts?${queryString}`;

    return fetch(url, {
      method: 'GET',
      headers: this.getPrivateHeaders(),
    })
      .then((response) => response.json())
      .then((data: fetchDepartmentsAndRolesMiddlewareAPIResponse) => {
        return {
          success: true,
          data: data,
          errorMessage: false,
        };
      })
      .catch((error) => {
        console.error(error);
        return {
          data: null,
          success: false,
          errorMessage: error,
        };
      });
  }

  public static async fetchScheduleDataForDepartment({departmentId, startDate, endDate}): Promise<{
    success: boolean;
    errorMessage: null | boolean;
    data: any;
  }> {
    const queryParams = {
      departmentId,
      startDate,
      endDate,
    };

    const queryString = qs.stringify(queryParams);

    const url = `${this.getMiddleWareApiEndpoint()}/oncall/shifts?${queryString}`;

    return fetch(url, {
      method: 'GET',
      headers: this.getPrivateHeaders(),
    })
      .then((response) => response.json())
      .then((data: fetchDepartmentsAndRolesMiddlewareAPIResponse) => {
        return {
          success: true,
          data: data,
          errorMessage: false,
        };
      })
      .catch((error) => {
        console.error(error);
        return {
          data: null,
          success: false,
          errorMessage: error,
        };
      });
  }

  public static async fetchFavouriteRolesMiddlewareAPIRequest({siteId}: {siteId: number}) {
    const queryParams = {
      siteId,
    };

    const queryString = qs.stringify(queryParams);

    const url = `${this.getMiddleWareApiEndpoint()}/oncall/favouriteRoles?${queryString}`;

    return fetch(url, {
      method: 'GET',
      headers: this.getPrivateHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        return {
          success: true,
          data: data,
          error: false,
        };
      })
      .catch((error) => {
        console.error(error);
        return {
          data: false,
          success: false,
          error: error,
        };
      });
  }

  public static async getSSOAuthorizationUrl(connectionId: string, provider: AuthSSOVendors) {
    const queryParams = {
      response_type: 'code',
      client_id: getAuthClientId(),
      redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
      connection_id: connectionId,
      provider,
    };

    const queryString = qs.stringify(queryParams);

    const url = `${this.getOAuthURL()}/sso/web/authorize?${queryString}`;

    console.log(url, 'url');

    try {
      const result = await fetch(url, {method: 'GET'});
      const data = await result.json();
      window.location.href = data.url;
    } catch (e) {
      console.error(e);
      return {
        data: false,
        error: e,
      };
    }
  }

  public static async addressVerificationRequest(payload: RequestVerificationPayload) {
    const url = `${this.getNotificationServiceApiEndpoint()}:3000/v1/challenges`;

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(payload),
    };

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        return {
          data: data,
        };
      })
      .catch((error) => {
        console.error('error', error);
      });
  }

  public static async ValidateAddressVerification(challengeId: string, otp: string) {
    const url = `${this.getNotificationServiceApiEndpoint()}:3000/v1/challenges/${challengeId}/validate`;

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      otp: otp,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data: ValidateAddressVerificationResponse) => {
        return {
          data: data,
        };
      })
      .catch((error) => {
        console.error('error', error);
        return {data: null, error};
      });
  }

  public static async addressVerificationRequestSTA(payload: RequestVerificationPayload) {
    const url = `${this.getNotificationServiceApiEndpoint()}:3500/v1/challenges`;

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${this.getAuthToken('accessToken')}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(payload),
    };

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        return {
          data: data,
        };
      })
      .catch((error) => {
        console.error('error', error);
      });
  }

  public static async validateAddressVerificationSTA(challengeId: string, otp: string) {
    const url = `${this.getNotificationServiceApiEndpoint()}:3500/v1/challenges/${challengeId}/validate`;

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${this.getAuthToken('accessToken')}`);

    var raw = JSON.stringify({
      otp: otp,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data: ValidateAddressVerificationResponse) => {
        return {
          data: data,
        };
      })
      .catch((error) => {
        console.error('error', error);
        return {data: null, error};
      });
  }
  public static async getAccounts({partialAccessToken}: {partialAccessToken: string}) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${partialAccessToken}`);

    const graphql = JSON.stringify({
      query: print(FETCH_ACCOUNTS_QUERY),
      variables: {},
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    try {
      const result = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data: GetAccountsForEmailResponse = await result.json();
      return data;
    } catch (e) {
      console.error(e);
      return {error: e};
    }
  }

  public static async getOrganizationByDomain({domain}: {domain: string}) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const graphql = JSON.stringify({
      query: print(FETCH_ORGANIZATION_BY_DOMAIN_QUERY),
      variables: {domain},
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
    };

    try {
      const result = await fetch('https://api.staging.hypercare.com:3000/v2/graphql/public', requestOptions);
      const data: FetchOrganizationByDomainResponse = await result.json();
      return data;
    } catch (e) {
      console.error(e);
      return {
        error: e,
      };
    }
  }
}

interface fetchDepartmentsAndRolesMiddlewareAPIResponse {
  site: {
    departments: LocatingDepartmentScope[];
  };
}

export default PrivateEndpoints;
